
import { defineComponent, ref } from "vue";
import moment from 'moment';
import SelectItem from "./SelectItem.vue";
import * as API from "@/API/closing";
import { message } from "ant-design-vue";
export default defineComponent({
  components: { SelectItem },
  setup() {
    const valueDate = ref<string>("");
    const valueDateList = ref<string[]>([]);
    const treeData = ref([
      {
        itemName: "Program Base",
        cbu: "",
        pbp: "",
        van: "",
        // denza: "",
        children: [
          {
            itemName: "Central",
            cbu: ".../Central/info",
            pbp: "Central",
            van: "Central",
            // denza: "central"
          },
          {
            itemName: "Central2",
            cbu: "",
            pbp: "",
            van: "",
            // denza: ""
          }
        ]
      },
      {
        itemName: "Blue Invoice",
        cbu: ".../Central/info",
        pbp: "Central",
        van: "Central",
        // denza: "central"
      }
    ]);
    const selectId = ref<number>(-1);
    const selectItem = ref<any>();
    const selectMake = ref("");
    //#region [获取年下拉]
    const getYears = async () => {
      const res: any = await API.getYearOptions();
      if (res.code === "0" && res.data) {
        valueDateList.value = res.data.reverse();
        const currentYear = moment().format('YYYY');
        if (valueDateList.value.includes(currentYear)) {
          valueDate.value = currentYear;
        } else {
          valueDate.value = res.data[0];
        }
        if (valueDate.value) {
          API.getTreeData(valueDate.value).then((ress: any) => {
            if (ress.code === "0") {
              treeData.value = ress.data;
            } else {
              message.error(ress.message);
            }
          });
        }
      } else {
        message.error(res.message);
      }
    };
    getYears();

    /**
     * 下拉框年份变化
     */
    const changeYear = () => {
      debugger;
      API.getTreeData(valueDate.value).then((ress: any) => {
        if (ress.code === "0") {
          treeData.value = ress.data;
        } else {
          message.error(ress.message);
        }
      });
    };
    //endregion
    const mode = ref("year");
    const handleChange = (val: any) => {
      valueDate.value = val;
    };
    const visible = ref<boolean>(false);
    // 打开弹窗
    const showModel = (id: number, item: any, make: string) => {
      selectId.value = id;
      selectItem.value = item;
      selectMake.value = make;
      visible.value = true;
    };

    const handleOk = (obj: any, id: number, make: string) => {
      const generate = (arr: any[]) => {
        arr.forEach(item => {
          // if (item.children?.length === 0) {
          if (item.id === id) {
            item[make] = obj;
            // }
          } else {
            generate(item.children);
          }
        });
      };
      generate(treeData.value);
    };
    return {
      valueDate,
      mode,
      handleChange,
      treeData,
      showModel,
      visible,
      valueDateList,
      handleOk,
      changeYear,
      selectId,
      selectItem,
      selectMake
    };
  }
});
