
import { defineComponent, reactive, ref } from "vue";
import { message } from "ant-design-vue";
import {
  getStructureList,
  getItemList,
  postItem,
  unBindItem,
  BindItem,
  saveTree,
} from "@/API/report";

export default defineComponent({
  emits: ["init-tree"],
  setup() {
    // context.emit("init-tree");
    const addVisible = ref(false);
    const itemVisible = ref(false);
    let selItem: any = [];
    const radioValue = ref(undefined); //默认无选中项
    const radioGrop: any = reactive([]);
    const radioStyle = reactive({
      display: "block",
      width: "400px",
      padding: "5px 10px",
      border: "1px solid #eee",
      margin: 0,
    });
    const expendActive = ref(false);
    const treeData: any = reactive([]);
    // 表单信息
    const formState: any = reactive({
      itemName: "",
      active: false,
    });
    // 必填项规则
    const rules = {
      itemName: [
        {
          required: true,
          message: " ",
          trigger: "blur",
        },
      ],
    };
    // 初始化树形控件是否有expend状态
    const initTreeData = (arr: any) => {
      arr.forEach((ele: any) => {
        ele.isExpend = true;
        if (ele.children && ele.children.length) {
          initTreeData(ele.children);
        }
        return arr;
      });
    };
    // 初始化树表数据
    const initStructureTable = () => {
      getStructureList().then((res: any) => {
        treeData.length = 0;
        if (res.length) {
          // eslint-disable-next-line
          // @ts-ignore
          treeData.push(...res);
        }
        initTreeData(treeData); //处理数据
      });
    };
    initStructureTable();
    // 初始化选项池数据
    const initItemPool = () => {
      getItemList()
        .then((res) => {
          radioGrop.length = 0;
          radioGrop.push(...res);
          itemVisible.value = true;
        })
        .catch((err) => {
          console.log(err, "报错");
        });
    };
    const radioChange = (val: any) => {
      radioGrop.forEach((ele: any) => {
        console.log(val.id);
        if (ele.id == val.id) {
          ele.isActive = !ele.isActive;
        }
      });
      selItem = radioGrop.filter((item: any) => {
        console.log(item)
        return item.isActive == true;
      });
      console.log(selItem);
      return radioGrop;
    };
    // 取消选中项
    const cancleItem = () => {
      radioGrop.forEach((ele: any) => {
        ele.isActive = false;
      });
      selItem = undefined;
      return radioGrop;
    };

    //  递归处理树形数据展开折叠状态
    const changeState = (arr: any, num: number) => {
      console.log(arr);
      arr.forEach((ele: any) => {
        if (ele.id === num) {
          ele.isExpend = !ele.isExpend;
          return arr;
        } else if (ele.children && ele.children.length) {
          changeState(ele.children, num); //递归调用
        }
      });
    };
    const expendRow = (active: any) => {
      changeState(treeData, active);
    };
    // 删除树形内数据的递归操作
    const delHandleTree = (arr: any, keyId: any) => {
      arr.forEach((ele: any, i: number) => {
        if (ele.key == keyId.key) {
          arr.splice(i, 1);
          return arr;
        } else {
          if (ele.children && ele.children.length) {
            delHandleTree(ele.children, keyId); //递归调用深度删除
          }
        }
      });
    };
    const Param: any = ref();
    const Level: any = ref();
    const addItem = (params: any, level: any) => {
      Param.value = params;
      Level.value = level;
      radioValue.value = undefined; //清空选项
      initItemPool(); //初始化数据
    };

    // 新增弹窗里的复选框选中状态监控
    const checkBoxB = (value: any) => {
      formState.active = value.target.checked;
    };
    const formRef = ref();
    const inputItem = () => {
      formState.itemName = "";
      addVisible.value = true;
      // formRef.value.resetFields();
    };
    // 新增弹窗保存按钮事件
    const handleOk = () => {
      if (formState.itemName && formState.itemName.trim()) {
        const tempItem = {
          createdAt: null,
          createdBy: null,
          activeStatus: formState.active,
          itemName: formState.itemName,
          updatedAt: null,
          updatedBy: null,
        };

        postItem(tempItem)
          .then((res) => {
            console.log(res, "这是添加的结果");
            initItemPool(); //刷新选项池数据
            setTimeout(() => {
              addVisible.value = false;
            }, 500);
          })
          .catch((err) => {
            console.log(err, "报错信息");
          });
      } else {
        formState.itemName = "";
        formState.active = false;
        message.error("请填写Report Item");
      }
    };
    const handleDel = (params: any) => {
      if (params.id) {
        unBindItem({ sid: params.parentId, itemId: params.id })
          .then(() => {
            message.success("unbind successful");
            initStructureTable();
          })
          .catch((err) => {
            console.log(err, "报错");
          });
      } else {
        delHandleTree(treeData, params);
      }
    };

    // 选择select Item数据按钮
    const confirmItem = () => {
      if (selItem.length) {
        if (Param.value == 0) {
          Param.value = {
            id: null,
          };
        }
        // 保存成功后再像树内插入数据
        saveTree(treeData).then(() => {
          initStructureTable();
          let ids: any='';
          selItem.forEach((ele: any) => {
            ids+=`${ele.id},`;
          });
           const params = {  itemIds: ids.substring(0,ids.length-1)}
          BindItem({params},Param.value.id).then(() => {
            message.success("bind successful");
            initStructureTable();
            itemVisible.value = false;
            selItem = undefined;
            Param.value = "";
            Level.value = "";
          });
        });
      } else {
        message.warning("please select item!");
      }
    };
    return {
      labelCol: { style: { width: "150px" } },
      wrapperCol: { span: 14 },
      formState,
      checkBoxB,
      rules,
      addVisible,
      itemVisible,
      cancleItem,
      radioValue,
      radioStyle,
      radioChange,
      selItem,
      radioGrop,
      treeData,
      expendRow,
      changeState,
      expendActive,
      handleOk,
      handleDel,
      addItem,
      inputItem,
      confirmItem,
      initStructureTable,
      formRef,
    };
  },
});
