
import { defineComponent, reactive, ref } from "vue";
import { getStructureList } from "@/API/report";

export default defineComponent({
  emits: ["handle-ok", "update:visible"],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    itemId: {
      default: -1,
      type: Number
    },
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    make: {
      type: String,
      default: ""
    }
  },
  setup(props, context) {
    const addVisible = ref(false);
    const itemVisible = ref(false);
    const expendActive = ref(false);
    const treeData: any = ref([]);
    // 表单信息
    const formState = reactive({
      itemName: "",
      active: false
    });
    // 必填项规则
    const rules = {
      itemName: [
        {
          required: true,
          message: " ",
          trigger: "blur"
        }
      ]
    };
    const backToItem = ref();
    //  递归处理树形数据展开折叠状态
    const changeState = (arr: any, num: number) => {
      arr.forEach((ele: any) => {
        if (ele.id === num) {
          ele.isExpend = !ele.isExpend;
          return arr;
        } else if (ele.children && ele.children.length) {
          changeState(ele.children, num); //递归调用
        }
      });
    };

    const expendRow = (active: any) => {
      changeState(treeData.value, active);
    };

    // 初始化树形控件是否有expend状态
    const initTreeData = (arr: any) => {
      arr.forEach((ele: any) => {
        ele.isExpend = true;
        if (ele.children && ele.children.length) {
          initTreeData(ele.children);
        }
        return arr;
      });
    };
    //查找父页面选中的项
    const getCheckedItem = (arr: [], id: number) => {
      arr.forEach((item: any) => {
        if (item.children?.length === 0) {
          item.activeStatus = false;
          if (item.id === id) {
            item.activeStatus = true;
            backToItem.value = item;
          }
        } else {
          getCheckedItem(item.children, id);
        }
      });
    };
    // 初始化树表数据
    const initStructureTable = () => {
      getStructureList().then((res: any) => {
        treeData.value.length = 0;
        if (res.length) {
          treeData.value = res;
          const generate = (dataArray: any[]) => {
            dataArray.forEach((item, index) => {
              item.activeStatus &&
                dataArray.splice(index, 1) &&
                generate(dataArray);
              item?.children?.length && generate(item.children);
            });
          };
          generate(treeData.value);
          getCheckedItem(treeData.value, props["itemId"]);
        }
        initTreeData(treeData.value); //处理数据
      });
    };
    initStructureTable();
    // 单选按钮数据处理
    const selectItem = (id: number) => {
      getCheckedItem(treeData.value, id);
    };
    const handleOk = () => {
      context.emit(
        "handle-ok",
        backToItem.value,
        props["itemId"],
        props["make"]
      );
      context.emit("update:visible", false);
    };
    return {
      formState,
      rules,
      addVisible,
      itemVisible,
      treeData,
      expendRow,
      changeState,
      expendActive,
      initStructureTable,
      selectItem,
      handleOk,
      getCheckedItem
    };
  }
});
