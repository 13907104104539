
import { defineComponent, ref } from "vue";
import { saveTree } from "@/API/report";
import { postSaveTreeData, getTreeData } from "@/API/closing";
import TreeTable from "@/views/Report/Components/TreeTable.vue";
import MappingTab from "@/views/Report/Components/MappingTab.vue";
import ScenarioTab from "@/views/Planning/ScenarioManagement.vue";
import { message } from "ant-design-vue";
export default defineComponent({
  components: { TreeTable, MappingTab, ScenarioTab },
  setup() {
    const tabActive = ref(0);
    const handleTab = (active: number) => {
      tabActive.value = active;
    };
    const structureEle = ref(); //structure节点
    const reportMapping = ref();

    // 保存structure和mapping表格
    const saveBtn = () => {
      if (tabActive.value == 0) {
        console.log("保存structure接口树形数据", structureEle.value.treeData);
        saveTree(structureEle.value.treeData)
          .then(() => {
            structureEle.value.initStructureTable();
          })
          .catch(err => {
            console.log(err, "报错");
          });
      } else {
        postSaveTreeData(reportMapping.value.treeData)
          .then((res: any) => {
            if (res.code === "0") {
              message.info("save successfully!");
              getTreeData(reportMapping.value.valueDate);
            }
          })
          .catch(err => {
            console.log(err, "报错");
          });
      }
    };
    return {
      tabActive,
      handleTab,
      saveBtn,
      structureEle,
      reportMapping
    };
  }
});
